html, body, div {
    box-sizing: border-box;
    font-family: sans-serif;
    margin: 0;
    padding: 0;
}

button, input, textarea, a {
    outline: none;
}

h1, h2, h3, h4, h5, p {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
